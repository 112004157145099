
/* Dropdown */

.dropdown {
    display: inline-block;
    position: relative;
  }
  
  .dd-button {
    display: inline-block;
   
    padding: 10px 30px 0px 0px;

    cursor: pointer;
    white-space: nowrap;
  }
  
  .dd-button:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(50%);
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }
  
  /* .dd-button:hover {
    background-color: #eeeeee;
  }
   */
  
  .dd-input {
    display: none;
  }
  
  .dd-menu {
    position: absolute;
    top: 100%;
    /* left: -55%; */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    /* box-shadow: 0 0 6px 0 rgba(0,0,0,0.1); */
    background-color: #ffffff;
    list-style-type: none;
    z-index: 20;
  }
  
  .dd-input + .dd-menu {
    display: none;
  } 
  
  .dd-input + .side-menu
  {
    display: none;
  }

  
  .dd-input:checked + .dd-menu {
    display: block;
  } 
  
  .dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
  }
  
  /* .dd-menu li:hover {
    background-color: #f6f6f6;
  } */
  
  .dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }
  
  .dd-menu li.divider{
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }


