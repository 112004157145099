.App {
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  /* background-color: grey; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* overflow: hidden; */
  font-family: "Segoe UI", Arial, sans-serif;
  /* z-index: -1; */
  overflow-x: hidden;

}
.email-login-button {
  background-color: rgb(227, 58, 58);
  color: white;
  font-size: 0.8em;
  padding: 10px 15px; /* adjust as needed */

  width: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
  color: #020202;
  font-size: 1 em;
  overflow: none;
}
.email-address:focus {
  outline: none;
  box-shadow: none;
}
.side-menu {
  width: 260px;
  background-color: #F5F5F5;
  border: none;
  padding: 20px;
  box-shadow: 1px 0px 10px 2.5px #7C5FE3;
  resize: none;
  color: #020202;
  font-size: 1.25 em;

}
.email-login-button:hover {
  background-color: #d42929;
}
.active-side-menu {
  display: flex;
  flex-direction: column;
}
.decactive-side-menu {
  display: none;
}
.chatbox {
  flex: 1;
  /* background-color: white; */
  background-color: #F6F6F6;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 1px;
  z-index: 3;
}
.side-menu-button {
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: #7C5FE3;
  color: #ffffff;
  text-align: left;
  
  width: 260px;
  transition: ease 0.25s all;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
}
.side-menu-button span{
  position: relative;
  top: -10px;
  left: 5px;
  font-size: 16px;
}
.email-login-button {
  background-color: #d42929;
  color: white;
}
.side-menu-header {
  margin-bottom: 10px;
  /* display: flex;
  flex-direction: row; */
  align-items: center;
  margin-bottom: 10px;
}

.side-menu-header-log {
  display: flex;
  flex-direction: row;
}
.side-menu-buttun span {
  padding-left: 4px;
  padding-right: 6px;
}
.side-menu-buttun:hover {
  background-color: rgb(255, 255, 255, 0.1);
  cursor: pointer;
}

.chatbox-input-area {
  /* padding: 10px 30px 2px 33px; */
  position: absolute;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;
}

.chatbox-input-textarea {
  background-color: #fff;
  width: 77%;
  margin-left: 10%;
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
  color: #020202;
  /* font-size: 1em; */
  /* min-height: 20px;  */
  max-height: 200px;
  overflow-y: auto; /* Add vertical scrolling when necessary */
  word-wrap: break-word; /* Enable word wrapping */
  white-space: pre-wrap;
  font-family: Arial, sans-serif;
}
.chatbox-input-textarea:focus {
  outline: 0;
  border-bottom: 2px solid #1e75e7;
}
.chat-log {
  color: black;
  text-align: left;
  /* display: flex; */
  
}

.chat-message {
  width: 100%;
}

.chat-message-chatgpt {
  background-color: rgb(255, 255, 255, 0.1);
  width: 93%;
}
.chat-message-center {
  padding: 8px;
  max-width: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
}

.avatar {
  /* background-color: rgb(12, 136, 181); */
  border-radius: 50%;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  margin-right: 10px;
  margin-top: 4px;
  
}

.user-avatar {
  /* background-color: #3b82f6; */
  border-radius: 50%;
}
.assist-image {
  border-radius: 50%;
  
  height: 40px;
  width: 40px;
  border: 1px solid #6666664a;
}
.user-image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.message {
  padding-top: 1px;
  margin-left: 1px;
  word-break: break-word;
  flex-grow: 1;
  font-size: 15px;
  height: auto;
}
.chat-message.user {
  background-color: #F5F5F5;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #6666662b;
}

.chat-message.assistant {
  background-color: #fff;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 10px;
  border: 1px solid #6666661b;
}

.chatbox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-log-container {
  flex-grow: 1;
  max-height: calc(115vh - 230px); /* Adjust the height as per your needs */
  width: auto;
  overflow: auto;
}
.logo-image {
  height: 55px;
  width: 55px;
  border-radius: 20%;
}
.top-left-image {
  /* padding-left: 5px; */
  /* padding-bottom: 15px; */
  height: 60px;
  width: 260px;
  
}

.loading-dots {
  display: inline-block;
}

.loading-wave {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.wave {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1e75e7;
  margin-right: 4px;
  animation: wave 3s infinite ease-in-out;
}
.brand-logo
{
   font-family: 'Rockwell Extra Bold,Rockwell Bold,monospace;';
}
@keyframes wave {
  0%,
  60%,
  100% {
    opacity: 0;
  }
  30%,
  80% {
    opacity: 1;
  }
}
.message-box {
  /* background-color: #f8f8fb; */
  /* padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px; */
  width: 98%;
  border: none;
  border-radius: 5px;
  /* box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3); */
  resize: none;
  color: #020202;
  font-size: 1 em;
  overflow: none;
}

.send-button {
  background-color: #edad53;
  border: none;
  height: 57px;
  width: 57px;
  border-radius: 5px;
  padding: 6px;
  padding-top: 1px;
  padding-bottom: 2px;
  margin-left: 5px;
  cursor: pointer;
  color: #e6e6f0;
  font-size: medium;
  font-weight: bold;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
  align-items: center;
}

.send-icon {
  height: 70px;
  width: 70px;
}

.send-icon {
  height: 18px;
  width: 18px;
}

.inner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8fb;
  padding-top: 5px;
}

.remaining-credit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.credit-text {
  margin-bottom: 5px;
}

.credit-bar {
  height: 10px;
  width: 80%;
  background-color: #8dea1b;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #070707; /* Add a border style */
}

.user-logo {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;

  width: 100%;
 
  
}
.user-logo img
{
 position: relative;
 left: -30px;
}
.user-logo p
{
  position: relative;
  top: -50px;
  left: 20px;
  font-weight: bold;
}

.user-logo-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

/* .logout-container {
  margin-top: 10px;
  width: 100%;
ima} */

.beta-image {
  height: 40px;
  width: 100px;
}

.logout-button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #edad53;
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  width: 230px;
  transition: ease 0.25s all;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
}
.credit-text {
  font-style: oblique;
  color: #1e75e7;
  font-weight: bold;
}
/* ...existing CSS rules... */

.popup-message {
  position: fixed;
  align-items: center;
  top: 50%;
  left: 50%;
  width: 30%; /* adjust width to taste */
  height: auto; /* adjust height to taste */
  transform: translate(-50%, -50%);
  background-color: #ffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.2);
  animation: popupFadeIn 0.5s forwards;
  background: blur;
  font-family: "Segoe UI", Arial, sans-serif !important;
}

/* .show-password {
  align-self: flex-end;
  margin-left: 110px;

  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
} */

.popup-content {
  text-align: center;
  padding-top: 10px;
  font-size: large;
  font-family: "Segoe UI", Arial, sans-serif !important;
}

.blur-background {
  filter: blur(1.5px);
  background-color: rgba(
    255,
    255,
    255,
    0.466
  ); /* Off white color with 70% opacity */
}
.modal-title {
  color: #000000; /* or any dark color you prefer */
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin-top: -10px;
  font-weight: 500;
  /* text-decoration: underline; */
  font-family: "Segoe UI", Arial, sans-serif !important;

  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6f0;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.gif-image-container {
  text-align: center;
  margin-top: 20px;
}

.gif-image {
  width: 250px;
  /* height: 450px; */
}

@keyframes popupFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}
.login-container div {
  margin-bottom: 5px;
}
.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border: none;
  border-radius: 4px;
  padding: 8px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 5px 0.25px rgb(0, 0, 0, 0.3);
  resize: none;
  color: #020202;
  font-size: 1 em;
  overflow: none;
  width: 209px;
}

.google-login-button:hover {
  background-color: #d42929;
}

.google-icon-container {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.google-icon {
  width: 100%;
  height: 100%;
  margin-right: 40px;
}

.google-login-text {
  padding-left: 5px;
  font-size: 16px;
  font-weight: 200px;
  font-family: "Segoe UI", Arial, sans-serif;
}
.scrolling-text-container {
  overflow: hidden;
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  color: #1e75e7;
  font-style: italic;
  animation: marquee 30s linear infinite;
}
.input-container {
  display: flex;
  flex-direction: row;
}

.question-image {
  height: 40px;
  width: 40px;
}
.toogle-button {
  display: none;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.popup-message.active + .App::after {
  display: block;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.tab {
 
  padding: 5px; /* Adjust the padding as per your requirement */
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: left;
  margin-top: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999;
}

.tab:hover {
  background-color: #e3e9f1;
}

.tab p {
  margin: 0;
}

.tab a {
  text-decoration: none;
  color: inherit;
}
.right-span {
  color: #01b0f0;
}

.disclaimer-container-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.answer-container-popup {
  /* background-color: #fff; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.disclaimer-content-popup {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  overflow: auto;
  text-align: left;
  max-width: 60%;
  max-height: 100%;
  z-index: 25;
}

.answer-content-popup {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;

  max-width: 60%;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 25;
}

.disclaimer-close-button-popup {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 70px;
  color: #999;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.disclaimer-close-button-popup:hover {
  color: #333;
}

.privacyclose-button:hover {
  color: red;
}

.example-image img {
  width: 70%;
  height: 75%;
}
.example-image
{
  display: flex;
  flex-direction: row;
  width: 81%;
  /* margin-left: 10%;
  margin-top: 350px; */
  position: relative;
  left: 10.3%;
  top: 430px;
}
.para-extab {
  text-align: center;
  font-size: 12px;
  color: #000;
  /* width: 80%; */

}
.reg-email {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  /* border-radius: 4px;
  box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #000000; */
}

.reg-email-content {
  width: 80%;
  margin-left: 10%;
  height: auto;
}

.input-group {
  position: relative;
  margin-bottom: 30px;
}

/* -------Error-Display------ */
.error-red {
  color: red;
  font-size: 12px;
  display: flex;
  /* right:80px; */
  text-indent: 13px; /* adjust this value to fit your needs */
  margin-top: 5px; /* Add some vertical spacing between the input and error message */
  flex-direction: row;
}
.name-error {
  color: red;
  font-size: 12px;
  display: flex;
  /* right:80px; */
  text-indent: 55px; /* adjust this value to fit your needs */
  margin-top: 5px; /* Add some vertical spacing between the input and error message */
  flex-direction: row;
}
.password-error {
  color: red;
  font-size: 12px;
  display: flex;
  /* right:80px; */
  text-indent: 55px; /* adjust this value to fit your needs */
  margin-top: 5px; /* Add some vertical spacing between the input and error message */
  flex-direction: row;
}

.input {
  padding: 10px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-bottom: 1px solid #edad53;
}
.placeholder-pos-aft-focus {
  top: -10px;
}
.placeholder-pos-normal {
  top: 10px;
}
.placeholder {
  position: absolute;
  left: 8px;
  font-size: 14px;
  padding: 0px 5px;
  color: #666;
  transition: 0.3s;
  pointer-events: none;
}
/* .input:focus {
  outline: none;
} */
.input:focus + .placeholder {
  top: -10px;
  left: 8px;
  /* color: #666;
  background-color: #fff; */
}

.input:focus {
  border-bottom: 2px solid #01b0f0;
}

/* .btn-content {
  display: flex;
  flex-direction: row;
  float: right;

} */
.btn-group {
  width: 100px;
  height: 30px;
  background-color: #01b0f0;
  border: none;
  margin: 5px;
  color: #fff;
  border-radius: 4px;
}
.btn-group:hover {
  cursor: pointer;
  border-bottom: 1px solid #000000;
}
.show-password {
  position: fixed;
  top: 310px;
  right: 80px;
}
.feedback-button {
  height: 30px;
  border: solid 1px #cccccc;
  border-radius: 8px;
  background: #fff;
  /* width: 105px; */
  /* line-height: 32px; */
  /* -webkit-transform: rotate(-90deg); */

  color: #000;
  /* transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg); */
  text-align: center;
  font-size: 16px;
  position: fixed;
  right: 80px;
  bottom: 5px ;
  /* top: 45%; */
  font-family: "Roboto", helvetica, arial, sans-serif;
  z-index: 999;
}
.feedback-button:hover {
  cursor: pointer;
}
.icon-button
{
  border: none;
  color: #000;
  padding: 6px 8px;
  cursor: pointer;
  text-align: left;
  
}
.icon-button span
{
  position: relative;
  top: -10px;
  left: 10px;
  font-size: 20px;
}
.tab span
{
  position: relative;
  top: 0px;
  left: 10px;
  font-size: 16px;
}
.fr-icons
{
  width: 30px;
  height: 30px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Cards  */

.card {   
  width: 100%; 
 
  
}



img {
  border-radius: 5px 5px 0 0;
}

.container-cards {
  padding: 0px 0px;
}
.cards-title
{
  text-align: center;
  font-size: 16px;
  padding-top: 20px;
}
.cards-icon
{
  text-align: center;
}

.cards-icon svg 
{
  width: 28px;
  height: 28px;
  position: relative;
  right: 10px;
 
}


.cards-item li
{ 
  border: 1px solid #ccc;
  transition: 0.3s;
  list-style-type: none;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  height: 70px;

}

.cards-item li:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  cursor: pointer;
  
}


.example-button
{ 
  border: 1px solid #ccc;
  transition: 0.3s;
  list-style-type: none;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 10px;  
  height: 60px; 
  width: 98%;
  background-color: #fff;
  margin-right: 2%;

}

.example-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  cursor: pointer;
  
}
.inside-btn
{
  display: flex;
  flex-direction: row;
  
}
.btn-text
{
  padding-right: 10px;
  text-align: left;
  width: 100%;
  color: #333;
}
.btn-icon svg
{
  width: 25px;
  height: 25px;
  color:#7C5FE3;
}

.gradient-border {
	/* background: #1D1F20; */
	position: relative;
	border-radius: 10px;
}

.gradient-border:after {
	content: '';
	position: absolute;
	top: calc(-1 * 2px);
	left: calc(-1 * 2px);
	height: calc(100% + 2px * 2);
	width: calc(100% + 2px * 2);
	background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	border-radius: 10px;
	z-index: -1;
	animation: animatedgradient 3s ease alternate infinite;
	background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}

/* Midea Query for mobile & tablet view */

/* For Desktop View */
@media screen and (min-width: 1024px) {
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .App {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
      
  }
  .side-menu {
    width: 100%;
    overflow-y: scroll;
    /* height: 150px; */
  }
  .chatbox {
    margin-left: 0px;
    overflow-y: scroll;
    
  }

  .side-menu-header {
    margin-bottom: 10px;
    /* border-bottom: 1px solid #333; */
    /* box-shadow: 0px 15px 15px -20px #111;     */
  }

  .side-menu-header-log {
    display: contents;
  }
  .popup-message {
    width: 40%; /* adjust width to taste */
  }
  .chatbox-input-area {
    padding-top: 5px;
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  /* .error-red {
    color: red;
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    text-indent: 15px;
  } */
  .name-error {
    color: red;
    font-size: 12px;
    display: flex;
    /* right:80px; */
    text-indent: 5px; /* adjust this value to fit your needs */
    margin-top: 5px; /* Add some vertical spacing between the input and error message */
    flex-direction: row;
  }
  .password-error {
    color: red;
    font-size: 12px;
    display: flex;
    /* right:80px; */
    text-indent: -13px; /* adjust this value to fit your needs */
    margin-top: 5px; /* Add some vertical spacing between the input and error message */
    flex-direction: row;
  }
  .top-left-image
  {
    margin-left: 35%;
  }
  .example-image
  {
    top: 80px;
  }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .App {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
      
  }
  .side-menu {
    width: 100%;
    overflow-y: scroll;
    /* height: 150px; */
  }
  .chatbox {
    margin-left: 0px;
    overflow-y: scroll;
    
  }

  .side-menu-header {
    margin-bottom: 10px;
    /* border-bottom: 1px solid #333; */
    /* box-shadow: 0px 15px 15px -20px #111;     */
  }

  .side-menu-header-log {
    display: contents;
  }
  .popup-message {
    width: 40%; /* adjust width to taste */
  }
  .chatbox-input-area {
    padding-top: 5px;
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  /* .error-red {
    color: red;
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    text-indent: 15px;
  } */
  .name-error {
    color: red;
    font-size: 12px;
    display: flex;
    /* right:80px; */
    text-indent: 5px; /* adjust this value to fit your needs */
    margin-top: 5px; /* Add some vertical spacing between the input and error message */
    flex-direction: row;
  }
  .password-error {
    color: red;
    font-size: 12px;
    display: flex;
    /* right:80px; */
    text-indent: -13px; /* adjust this value to fit your needs */
    margin-top: 5px; /* Add some vertical spacing between the input and error message */
    flex-direction: row;
  }
  .top-left-image
  {
    margin-left: 35;
  }
  .example-image
  {
    top: 80px;
  }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .side-menu {
    width: 20%;
    /* height: 150px; */
    overflow-y: scroll;
  }
  .chatbox {
    margin-left: 0px;
  }
  .side-menu-header-log {
    display: contents;
  }
  .popup-message {
    width: 50%; /* adjust width to taste */
  }
  .chatbox-input-area {
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  .top-left-image {
    margin-left: 40%;
}
  
 
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .App {
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
  .side-menu {
    width: 100%;
    /* height: 150px; */
    overflow-y: scroll;
  }
  .show-password {
    position: absolute;
    right: 5px;
    top: 7px;
  }
  .active-side-menu {
    transition: "1s, transform 2s";
  }

  .chatbox {
    margin-left: 0px;
    overflow: scroll;
  }
  .popup-message {
    width: 50%; /* adjust width to taste */
  }
  .chatbox-input-area {
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  /* .chat-log
  {
    margin-bottom: 50px;
  } */
  .example-image
  {
    top: 80px;
  }
  .top-left-image {
    margin-left: 18%;
}
}

/* For iPhone 4 1ortrait or Landscape View */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .App {
    display: flex;
    flex-direction: column;
  }
  .side-menu {
    width: 100%;
    /* height: 150px; */
    overflow-y: scroll;
  }
  .chatbox {
    margin-left: 0px;
  }
  .popup-message {
    width: 70%; /* adjust width to taste */
  }
  /* .show-password {
    position: absolute;
    right: 10px;
    top: 10px;
  } */

  .chatbox-input-area {
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
    padding-top: 5px;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }

  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  /* .chat-log
  {
    margin-bottom: 50px;
  } */
  .example-image
  {
    flex-direction: column;
    top: 40px;
  }
  .card
  {
    margin-top: 0px;
    width: 100%;
  }
  .cards-item li
  {
    width: 80%;
    height: 50px;
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .App {
    display: flex;
    flex-direction: column;
  }

  .side-menu {
    width: 100%;
    /* height: 150px; */
    overflow-y: scroll;
  }
  .chatbox {
    margin-left: 0px;
  }
  .popup-message {
    width: 70%; /* adjust width to taste */
  }
  .chatbox-input-area {
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .send-button {
    height: 42px;
  }

  .message-box {
    margin-bottom: 10px;
  }
  /* .chat-log
  {
    margin-bottom: 50px;
  } */
  
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .App {
    display: flex;
    flex-direction: column;
  }

  .side-menu {
    width: 100%;
    /* height: 150px; */
    overflow-y: scroll;
  }
  .chatbox {
    margin-left: 0px;
  }
  .popup-message {
    width: 70%; /* adjust width to taste */
  }
  .chatbox-input-area {
    position: fixed;
    z-index: 10;
    background-color: #e8e8e8;
  }
  .toogle-button {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 5;
    top: 2px;
    display: block;
  }
  .tabs-container {
    margin-top: 10px;
  }
  .chatbox-input-textarea {
    padding: 10px 10px 2px 10px;
    height: 30px;
  }
  .send-button {
    height: 42px;
  }
  .message-box {
    margin-bottom: 10px;
  }
  .example-image
  {
    top: 80px;
  }
  
  /* .chat-log
  {
    margin-bottom: 50px;
  } */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {


}



@keyframes blink {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.type-writer
{
  white-space: pre-wrap;
}

.type-writer::after {
  content: "|";
  animation: blink 1s infinite;
}
