.disclaimer-container {
  max-width: 1000px;
  max-height: 500px;
  /* height: auto; */
  padding-top: 20px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f3f4f4;
  border-radius: 8px;
  font-size: smaller;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
}
.upper-container {
  padding-top: 150px;
}

.checkbox-container {
  margin-top: 20px;
}

.disc-text {
  color: black;
  align-items: center;
}

.acknowledge-button {
  display: block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #edad53;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.acknowledge-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
